var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(_vm._s(_vm.$t("configuration.title"))),
              ]),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.$t("configuration.theme"))),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-action", [_c("theme-selector")], 1),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  _vm._s(_vm.$t("configuration.language"))
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-action",
                            [
                              _c("locale-selector", {
                                attrs: {
                                  disableSwitch: "",
                                  langs:
                                    _vm.$store.getters[
                                      "module_locale/getAvailableLocales"
                                    ],
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }