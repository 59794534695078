<template>
  <v-container fluid >
  <v-row no-gutters justify="center">

    <v-col cols="8">
      <v-card>
            <v-card-title>{{$store.getters['module_configurations/getProfessionalData'].job.description}}</v-card-title>
            <v-card-subtitle>{{$store.getters['module_configurations/getProfessionalData'].name}}</v-card-subtitle>
            <v-card-text>
                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" lg="5" xl="3"  style="text-align:center;" >
                      <v-avatar class="profile" :size="avatarSize" :max-height="avatarSize" :max-width="avatarSize" >
                          <v-img src="/image/avatar/avatar.svg"></v-img>
                      </v-avatar>
                  </v-col>
                  <v-col cols="12" lg="7" xl="9"  >
                    <pre class="pre-inherited" >
                      {{$t("texts.summary")}}
                    </pre>
                  </v-col>
                </v-row>
              </v-card-text>
        </v-card>
      </v-col> 
    </v-row>
  </v-container>
</template>
   

<script>
export default {
  name: "Home",
  components: {
  },
  computed: {
    breakPoint(){return this.$vuetify.breakpoint.name;},
    avatarSize(){
      return "256px";
    }
  }
};
</script>
<style>

</style>