var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [
                    _vm._v(
                      _vm._s(
                        _vm.$store.getters[
                          "module_configurations/getProfessionalData"
                        ].job.description
                      )
                    ),
                  ]),
                  _c("v-card-subtitle", [
                    _vm._v(
                      _vm._s(
                        _vm.$store.getters[
                          "module_configurations/getProfessionalData"
                        ].name
                      )
                    ),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            "no-gutters": "",
                            align: "center",
                            justify: "center",
                          },
                        },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { cols: "12", lg: "5", xl: "3" },
                            },
                            [
                              _c(
                                "v-avatar",
                                {
                                  staticClass: "profile",
                                  attrs: {
                                    size: _vm.avatarSize,
                                    "max-height": _vm.avatarSize,
                                    "max-width": _vm.avatarSize,
                                  },
                                },
                                [
                                  _c("v-img", {
                                    attrs: { src: "/image/avatar/avatar.svg" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", lg: "7", xl: "9" } },
                            [
                              _c("pre", { staticClass: "pre-inherited" }, [
                                _vm._v(
                                  "                    " +
                                    _vm._s(_vm.$t("texts.summary")) +
                                    "\n                  "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }