import Vue from "vue";
import Vuex from "vuex";
import simpleBrowserFingerprint from "simple-browser-fingerprint";
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";
import module_menu from './modules/module_menu';
import module_theme from './modules/module_theme';
import module_locale from './modules/module_locale';
import module_configurations from './modules/module_configurations';


const ls = new SecureLS({
  encryptionNamespace:'8C174C7086F64DBEAFBDF979405A9A36',
  encodingType: "aes",
  encryptionSecret: `${simpleBrowserFingerprint()}`,
  isCompression: false
});
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  modules: {
    module_menu:module_menu,
    module_theme:module_theme,
    module_locale:module_locale,
    module_configurations:module_configurations,
  },
  plugins: [
    createPersistedState({
      key:'8C174C7086F64DBEAFBDF979405A9A36',
      paths:[
        "module_theme.theme",
        "module_locale.locale",
        "module_menu.menuState",
      ],
      storage: {
        getItem: (key) =>{const result = ls.get(key); console.log(result); return result;},
        setItem: (key, value) =>  ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },

    })
  ],
  getters: {},
  mutations: {},
  actions: {},
  
});
