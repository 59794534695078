<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
        <v-card>
        <v-card-title >{{$t('configuration.title')}}</v-card-title>
        <v-divider/>
        <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{$t('configuration.theme')}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action><theme-selector /></v-list-item-action>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{$t('configuration.language')}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <locale-selector disableSwitch :langs="$store.getters['module_locale/getAvailableLocales']"/>
                </v-list-item-action>
              </v-list-item>

            </v-list>
            
        </v-card-text>
        <v-divider/>
      </v-card>
      <!-- <v-card>
        <v-card-title >{{$t('configuration.title')}}</v-card-title>
        <v-divider/>
        <v-card-text>
            <p>{{$t('configuration.theme')}}</p>
            <theme-selector/>
        </v-card-text>
        <v-divider/>
        <v-card-text>
            <p>{{$t('configuration.language')}}  </p>
            <locale-selector disableSwitch :langs="$store.getters['module_locale/getAvailableLocales']"/>
        </v-card-text>
      </v-card> -->
    </v-dialog>
  </div>
</template>
<script>
import LocaleSelector from '@components/LocaleSelector';
import ThemeSelector from '@components/ThemeSelector';
export default {
    name: 'NavigationBar',
    components: {
        ThemeSelector,
        LocaleSelector,
    },  
    data () {
      return {
        dialog: false,
      }
    },
    methods:{
        show(){
          this.dialog = true;
        },
        close(){
          this.dialog = false;
        },
    }
  }
</script>