var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { app: "", "clipped-left": "" } },
        [
          _c(
            "v-app-bar-title",
            [
              _c(
                "router-link",
                { staticStyle: { cursor: "pointer" }, attrs: { to: "/" } },
                [
                  _vm.$store.getters["module_theme/isDarkThemeSet"]
                    ? _c("v-img", {
                        attrs: {
                          contain: "",
                          width: "64px",
                          height: "64px",
                          src: "/image/icons/dark/icon.svg",
                        },
                      })
                    : _c("v-img", {
                        attrs: {
                          contain: "",
                          width: "64px",
                          height: "64px",
                          src: "/image/icons/light/icon.svg",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-btn",
            { key: "home", attrs: { plain: "", "x-large": "", to: "/" } },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$store.getters[
                      "module_configurations/getProfessionalData"
                    ].name
                  ) +
                  " "
              ),
            ]
          ),
          _c(
            "v-toolbar-items",
            { staticClass: "hidden-md-and-down" },
            [
              _c("menu-items", {
                attrs: {
                  items: _vm.items.filter(
                    (i) => i.visible && i.align === "left"
                  ),
                  plain: "",
                },
              }),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-toolbar-items",
            { staticClass: "hidden-md-and-down" },
            [
              _c("menu-items", {
                attrs: {
                  items: _vm.items.filter(
                    (i) => i.visible && i.align === "right"
                  ),
                  plain: "",
                  icon: "",
                  "x-large": "",
                },
              }),
            ],
            1
          ),
          _c("v-app-bar-nav-icon", {
            staticClass: "hidden-lg-and-up",
            on: {
              click: [
                function ($event) {
                  $event.preventDefault()
                },
                function ($event) {
                  $event.stopPropagation()
                  return _vm.toogleMenu()
                },
              ],
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("navigation-bar", {
            ref: "menu",
            staticClass: "hidden-lg-and-up",
            attrs: {
              onMenuClosed: _vm.onMenuClosed,
              items: _vm.items.filter((i) => i.visible),
            },
          }),
          _c("configuration-modal", { ref: "configurationModal" }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }