import { render, staticRenderFns } from "./LocaleSelector.vue?vue&type=template&id=9fe5b3ee&scoped=true&"
import script from "./LocaleSelector.vue?vue&type=script&lang=js&"
export * from "./LocaleSelector.vue?vue&type=script&lang=js&"
import style0 from "./LocaleSelector.vue?vue&type=style&index=0&id=9fe5b3ee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fe5b3ee",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/buildhome/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9fe5b3ee')) {
      api.createRecord('9fe5b3ee', component.options)
    } else {
      api.reload('9fe5b3ee', component.options)
    }
    module.hot.accept("./LocaleSelector.vue?vue&type=template&id=9fe5b3ee&scoped=true&", function () {
      api.rerender('9fe5b3ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/LocaleSelector.vue"
export default component.exports