module.exports={
    menu:{
        home: "Inicio",
        closeMenu: "Fechar Menu",
        configurations: "Configurações",
       
    },
    appbar:{
        contact: "Contato",
        competencies: "Competências",
        workExperience: "Experiência de Trabalho",
        configurations: "Configurações",
        linkedin: "Linkedin",
        github: "Github",
        stackOverflow: "Stack Overflow",
    },
    configuration:{
        title:"Configurações",
        language: "Idioma",
        theme: "Tema",
    },
    variations:{
        information:{
            text: 'informação | informações',
            Text: 'Informação | Informações',
            textCammelCased: 'Informação | Informações',
            count: 'nenhuma informação | uma informação | {count} informações',
            Count: 'Nenhuma informação | Uma informação | {count} informações',
            countCammelCased: 'Nenhuma Informação | Uma Informação | {count} Informações',
        },
    },
    texts:{
        summary:`
        Programador C# com anos de experiência desenhando, arquitetando, desenvolvendo, 
        analisando e implementando client/server, web e aplicações desktop.  Especialista em 
        projetar e testar sistema, debugar e desenvolver soluções. Disposto a aprender novas 
        linguagens. 
        Com habilidades de gerenciamento de projetos, solução de problemas,
        documentação, análise e tomadas de decisões.`
    }
}
