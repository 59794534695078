var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: { app: "" },
      model: {
        value: _vm.openMenu,
        callback: function ($$v) {
          _vm.openMenu = $$v
        },
        expression: "openMenu",
      },
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        _vm._l(_vm.items, function (item) {
          return _c(
            "v-list-item",
            {
              key: item.id,
              attrs: {
                to:
                  item.type === "link" && item.to && !item.href
                    ? item.to
                    : undefined,
                href:
                  item.type === "link" && !item.to && item.href
                    ? item.href
                    : undefined,
                target:
                  item.type === "link" && !item.to && item.href
                    ? item.target
                    : undefined,
                "active-class": "active-list-item",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  item.type === "button" ? item.function(item) : undefined
                },
              },
            },
            [
              _c(
                "v-list-item-icon",
                [
                  !item.icon.color
                    ? [_c("v-icon", [_vm._v(_vm._s(item.icon.key))])]
                    : [
                        _c("v-icon", { attrs: { color: item.icon.color } }, [
                          _vm._v(_vm._s(item.icon.key)),
                        ]),
                      ],
                ],
                2
              ),
              _c(
                "v-list-item-content",
                [
                  item.content.title.locale.count
                    ? _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                item.content.title.locale.key,
                                item.content.title.locale.count
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _c("v-list-item-title", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t(item.content.title.locale.key)) +
                            " "
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }