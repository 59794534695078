var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    _vm._l(_vm.items, function (item) {
      return _c(
        "v-btn",
        {
          key: item.id,
          attrs: {
            text: "",
            plain: _vm.plain,
            "x-large": _vm.xLarge,
            icon: _vm.icon,
            to:
              item.type === "link" && item.to && !item.href
                ? item.to
                : undefined,
            href:
              item.type === "link" && !item.to && item.href
                ? item.href
                : undefined,
            target:
              item.type === "link" && !item.to && item.href
                ? item.target
                : undefined,
            "active-class": "active-list-item",
          },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              item.type === "button" ? item.function(item) : undefined
            },
          },
        },
        [
          item.icon
            ? _c("v-icon", { class: item.icon.class }, [
                _vm._v(_vm._s(item.icon.key)),
              ])
            : _vm._e(),
          item.content
            ? _c(
                "span",
                { class: item.content.class },
                [
                  item.content.title.locale.count
                    ? [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$tc(
                                item.content.title.locale.key,
                                item.content.title.locale.count,
                                item.content.title.locale.parameters
                              )
                            ) +
                            " "
                        ),
                      ]
                    : [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                item.content.title.locale.key,
                                item.content.title.locale.parameters
                              )
                            ) +
                            " "
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }