const { generalInformation } = require("../pt-BR/messages");

module.exports={
    menu:{
        home: "Home",
        closeMenu: "Close menu",
        configurations: "Configurations",

    },
    appbar:{
        contact: "Contact",
        competencies: "Competencies",
        workExperience: "Work Experience",
        configurations: "Configurations",
        linkedin: "Linkedin",
        github: "Github",
        stackOverflow: "Stack Overflow",
    },
    configuration:{
        title:"Configurations",
        language: "Language",
        theme: "Theme",
    },
    variations:{
        information:{
            text: 'information | informations',
            Text: 'Information | Informations',
            textCammelCased: 'Information | Informations',
            count: 'no information | an information | {count} informations',
            Count: 'No information | An information | {count} informations',
            countCammelCased: 'No Information | An Information  | {count} Informations',
        },
    },
    texts:{
        summary:`
        Self-directed, detail-oriented, and professional C# programmer with
        years of experience in designing, architecting, developing, analyzing
        and implementing client-server, web and desktopbased applications using 
        C# language. 
        Expert in system  designing as well as testing, debugging 
        and modifying related application code. Open to learn new programming 
        languages and technologies. Possess problemsolving, project management,
        documentation, analytical, and decision making skills.`
    }
}