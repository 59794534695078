//import createPersistedState from 'vuex-persistedstate';
const module = {
  namespaced: true,
  state: {
    professional:{
      name: 'Vinicius Andrade',
      job:{
        description: 'Software Engineer',
      }
    }

  },
  plugins: [  ],
  modules: {
  },
  mutations: {
    set_locale(state, locale) {
      //state.locale = locale;
    }
  },
  actions: {
    setLocale({ commit }, locale) {
      commit('set_locale', locale);
    },

  },
  getters: {
    getProfessionalData:(state) => state.professional,
  }
};
export default  module;
