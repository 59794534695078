var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      !_vm.iconOnly
        ? _c("v-switch", {
            attrs: { "append-icon": _vm.appendIcon ? _vm.icon : undefined },
            model: {
              value: _vm.darkMode,
              callback: function ($$v) {
                _vm.darkMode = $$v
              },
              expression: "darkMode",
            },
          })
        : _c("v-icon", [_vm._v(_vm._s(_vm.icon))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }