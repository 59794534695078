var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.hasMultipleLanguages
      ? _c(
          "div",
          [
            _vm.enableSwitch
              ? _c("v-switch", {
                  attrs: {
                    "input-value": _vm.$i18n.locale,
                    "true-value": _vm.languages[0],
                    dark: _vm.dark,
                    color: _vm.color,
                    "false-value": _vm.languages[1],
                    label: _vm.$i18n.locale,
                  },
                  on: { change: _vm.changeLocale },
                })
              : _c(
                  "v-col",
                  { attrs: { width: _vm.selectWidth } },
                  [
                    _c("v-select", {
                      attrs: {
                        items: _vm.languages,
                        dark: _vm.dark,
                        color: _vm.color,
                      },
                      on: { change: _vm.changeLocale },
                      model: {
                        value: _vm.$i18n.locale,
                        callback: function ($$v) {
                          _vm.$set(_vm.$i18n, "locale", $$v)
                        },
                        expression: "$i18n.locale",
                      },
                    }),
                  ],
                  1
                ),
          ],
          1
        )
      : _c("div", { class: _vm.textColorClassPersisted }, [
          _vm._v(" " + _vm._s(_vm.languages[0]) + " "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }