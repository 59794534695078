<template>
    <div>
      <v-app-bar app clipped-left>
        <!-- <v-toolbar> -->
        <v-app-bar-title>
          <router-link to="/"  style="cursor: pointer" >
           <v-img v-if="$store.getters['module_theme/isDarkThemeSet']" contain width="64px" height="64px"  src="/image/icons/dark/icon.svg"  />
           <v-img v-else contain width="64px" height="64px"  src="/image/icons/light/icon.svg"  />
          </router-link>
        </v-app-bar-title>
        <v-btn  plain x-large  key="home" to="/">
          {{ $store.getters['module_configurations/getProfessionalData'].name }}
        </v-btn>
        <v-toolbar-items class="hidden-md-and-down">
          <menu-items :items="items.filter(i=>i.visible && i.align==='left')" plain/>
        </v-toolbar-items>
        <v-spacer />
        <v-toolbar-items class="hidden-md-and-down">
          <menu-items :items="items.filter(i=>i.visible && i.align==='right')" plain icon x-large/>
        </v-toolbar-items>
        <v-app-bar-nav-icon class="hidden-lg-and-up"  v-on:click.prevent @click.stop="toogleMenu()"/>
         <!-- @click="sidebar = !sidebar"/> -->
      </v-app-bar>
      <div>
        <navigation-bar  ref="menu" :onMenuClosed="onMenuClosed" :items="items.filter(i=>i.visible)" class="hidden-lg-and-up"/>
        <configuration-modal ref="configurationModal"/>
      </div>
    </div>
</template>
<script>
import ConfigurationModal from '@modals/ConfigurationModal';
import pluralizationTypes from '@constants/pluralizationTypes';
import NavigationBar from "@components/NavigationBar.vue";
import MenuItems from "@components/MenuItems.vue";
export default {
  name: 'AppBar',
  components: {
    ConfigurationModal,
    NavigationBar,
    MenuItems,
  },
  props:{
    permanent: {
      type: Boolean,
      default: false,
    },
    enableNavigationBar:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      openMenu:true,
      mini:false,
      items: [
          { 
            visible: false,
            align: 'left',
            icon:{key: 'mdi-briefcase',class:'hidden-md-and-up'},
            content:{
              class:null, 
              title:{
                locale:{key:'appbar.workExperience', count:null},
              }
            },
            id:'workExperience',
            type: 'link',
            to: '/about/work/experience',
            target:null,
            function:this.openInternalUrl
          },
          { 
            visible: false,
            align: 'left',
            icon:{key: 'mdi-linkedin',class:'hidden-md-and-up'},
            content:{
              class:null, 
              title:{
                locale:{key:'appbar.competencies', count:null},
              }
            },
            id:'competencies',
            type: 'link',
            to: '/about/work/competencies',
            target:null,
            function:this.openInternalUrl
          },
          { 
            visible: false,
            align: 'left',
            icon:{key: 'mdi-phone',class:'hidden-md-and-up'},
            content:{
              class:null, 
              title:{
                locale:{key:'appbar.contact', count:null},
              }
            },
            id:'contact',
            type: 'link',
            to: '/about/contact',
            target:null,
            function:this.openInternalUrl
          },
           { 
            visible: true,
            align: 'right',
            icon:{key: 'mdi-linkedin',class:null},
            content:{
              class:'hidden-md-and-up', 
              title:{
                locale:{key:'appbar.linkedin', count:null},
              }
            },
            id:'linkedin',
            type: 'link',
            href: 'https://linkedin.com/in/dev-vinicius-andrade',
            target:"_blank",
            function:this.openExternalUrl
          },
          { 
            visible: true,
            align: 'right',
            icon:{key: 'mdi-github',class:null},
            content:{
              class:'hidden-md-and-up', 
              title:{
                locale: {key:'appbar.github', count:null},
              }
            },
            id:'github',
            type: 'link',
            href: 'https://github.com/dev-vinicius-andrade',
            target:"_blank",
            function:this.openExternalUrl
            
          },
          { 
            visible: true,
            align: 'right',
            icon:{key: 'mdi-stack-overflow',class:null},
            content:{
              class:'hidden-md-and-up', 
              title:{
                locale: {key:'appbar.stackOverflow', count:null},
              }
            },
            id:'stack-overflow',
            type: 'link',
            href: 'https://stackoverflow.com/users/9114389/vinicius-andrade',
            target:"_blank",
            function:this.openExternalUrl
          },
          {  
            visible: true,
            align: 'right',
            icon:{key:'mdi-cog',class:null} ,
            content:{
              class:'hidden-md-and-up', 
              title:{
                locale: {key:'appbar.configurations', count:null},
              }
            },
            id:'configurations',
            type: 'button',
            function: this.openConfigurationModal
          },
      ],
    };
  },
  computed: {
  },
  methods: {
    onMenuClosed() {
      this.openMenu = false;
      this.$forceUpdate();
    },
    toogleMenu() {
      this.$store.commit('module_menu/toogle_menu')
    },
    openExternalUrl(item) {
      window.open(item.href, item.target);
    },
    openInternalUrl(item, replace=false) {
       this.$router.push({path: item.to, replace: replace});
    },
    openConfigurationModal() {
        this.$refs.configurationModal.show();
    },
  }
}
</script>
<style scoped>
.active-list-item{
  background-color: var(--v-active_menu_color);
  /* //Rgba(0,0,0,0.1); */
}
</style>