import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import axios from "axios";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import utils from '@plugins/utils';
import i18n from '@locales/i18n';
import pluralizationTypes from '@/constants/pluralizationTypes';
import colors from 'vuetify/lib/util/colors'

async function main(){
  Vue.use(utils, { store });
  Vue.config.productionTip = false;
  //Vue.prototype.$config = config;
  Vue.prototype.$pluralizationTypes = pluralizationTypes;
  Vue.prototype.$colors =colors;
  
  new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
}
main();